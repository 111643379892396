/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    minecart: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4 15a1 1 0 110-2 1 1 0 010 2m0 1a2 2 0 100-4 2 2 0 000 4m8-1a1 1 0 110-2 1 1 0 010 2m0 1a2 2 0 100-4 2 2 0 000 4M.115 3.18A.5.5 0 01.5 3h15a.5.5 0 01.491.592l-1.5 8A.5.5 0 0114 12H2a.5.5 0 01-.491-.408l-1.5-8a.5.5 0 01.106-.411zm.987.82l1.313 7h11.17l1.313-7z"/>',
    },
});
